import { gql } from '@apollo/client'

const updateShareScoreResponseKey = 'updateShareFGScore'
const updateUserShareFGscore = gql`
  mutation UpdateShareFGScore($email: String, $status: ShareStatus!, $frequency: ReportFrequency!) {
    ${updateShareScoreResponseKey}(email: $email, status: $status, frequency: $frequency) {
      id
      email
      status
      frequency
    }
  }
`

const activateShareScoreResponseKey = 'activateShareFGScore'
const activateShareFGscore = gql`
  mutation ActivateShareFGscore($shareId: String , $acceptance: ObserverStatus) {
    ${activateShareScoreResponseKey}(shareId: $shareId, acceptance: $acceptance) {
      id
      email
      status
      frequency
    }
  }
`

export const SHARE_SCORE_MUTATIONS = {
  UPDATE: updateUserShareFGscore,
  ACTIVATE: activateShareFGscore,
}
