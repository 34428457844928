import React, { PropsWithChildren, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { DeleteIcon } from '@chakra-ui/icons'

import {
  Box,
  Button,
  Flex,
  Spacer,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  Text,
} from '@chakra-ui/react'
import { Form, Formik, Field, FieldProps } from 'formik'

import CustomSelect from '../../shared/ui/select'

import ConfirmModal from '../../shared/ui/confirm-modal/ConfirmModal'
import {
  CategoryOption,
  PillarVideoCategories,
  VideoRecommendationType,
} from '../Video.recommendation.types'
import { useVaultCategories } from '../../../hooks/useVaultCategories'
import getVaultState, {
  IVaultCategory,
  vaultCategories,
} from '../../../pages/VideoRecommendation/videosGroupToVault'

import 'react-quill/dist/quill.snow.css' // ES6
import './RecommendationUpdateForm.scss'

interface UpdateComponentProps extends PropsWithChildren<any> {
  onDataChange?: (data: any) => void
  onSubmit: (data: any) => void
  initialData: VideoRecommendationType
  isSubmitting?: boolean
}

const metadataFormSchema = Yup.object().shape({
  title: Yup.string()
    .max(40, 'Title should be less than 40 characters.')
    .required('A title is required.'),
  pillarSlug: Yup.string().required('Pillar Category is required.'),
  questionSlug: Yup.string().required('Question is required.'),
  answer: Yup.string().required('Answer is required.'),
  videoId: Yup.string().required('Video is required.'),
  description: Yup.string()
    .max(1000, 'Description should be less than 200 characters.')
    .nullable(),
})

const RecommendationUpdateForm: React.FC<UpdateComponentProps> = ({
  onSubmit,
  initialData,
  isSubmitting,
  loading,
  selectedRow,
  handleConfirmDelete,
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [modalBody, setModalBody] = useState<React.ReactElement>(<></>)

  const { getVaultCategories, categories, pillarVideoResponse } =
    useVaultCategories()

  const fetchDailyVideos = async () => {
    getVaultCategories()
  }

  const getVaultValue = () => {
    if (initialData?.Video) {
      const vault = getVaultState([initialData.Video])
      const categories = vaultCategories.filter(
        (item) => vault[item.type]?.length > 0
      )
      return categories[0].type
    }
    return null
  }
  useEffect(() => {
    if (initialData?.pillarSlug) {
      fetchDailyVideos()
    }
  }, [initialData?.pillarSlug])

  const pillarCategoryOptions = PillarVideoCategories.map((pillar: any) => ({
    label: Object.keys(pillar)[0],
    value: Object.keys(pillar)[0],
  }))

  const getQuestions = (selectedKey: any) => {
    const selectedObject = PillarVideoCategories.find(
      (obj) => Object.keys(obj)[0] === selectedKey
    )

    if (selectedObject) {
      const activitiesArray = selectedObject[selectedKey].map(
        (activityObj: CategoryOption) => ({
          value: activityObj.activity,
          label: activityObj?.label || activityObj.activity,
        })
      )
      return activitiesArray
    }

    return []
  }

  const getAnswers = (selectedPillar: string, selectedQuestion: string) => {
    const selectedObject = PillarVideoCategories.find(
      (obj) => Object.keys(obj)[0] === selectedPillar
    )

    if (selectedObject) {
      const selectedQuestionObject = selectedObject[selectedPillar].find(
        (activityObj: any) => activityObj.activity === selectedQuestion
      )
      return (
        selectedQuestionObject?.options.map((activity: string) => ({
          label: activity,
          value: activity,
        })) || []
      )
    }

    return []
  }

  const getCategories = categories.map((category: IVaultCategory) => ({
    label: category.title,
    value: category.type,
  }))

  const handleDeleteConfirmation = (selectedRow: VideoRecommendationType) => {
    if (selectedRow) {
      const body = (
        <Text textAlign="center">
          You are about to delete{' '}
          <>
            <Text fontSize="1.15rem" fontWeight="bold">
              {selectedRow.title}
            </Text>
          </>
          Are you sure you want to proceed?
        </Text>
      )
      setModalBody(body)
      setOpenConfirmModal(true)
    }
  }

  const getVideoOptions = (vaultCategory: string | null) => {
    if (vaultCategory && pillarVideoResponse?.videos) {
      const vaultState = getVaultState(pillarVideoResponse?.videos)
      const videosInCategory = vaultState[vaultCategory]

      if (videosInCategory) {
        return videosInCategory.map((video) => ({
          label: video.title || '',
          value: video.id,
        }))
      }
    }

    return []
  }

  return (
    <>
      <Box>
        <Formik
          validationSchema={metadataFormSchema}
          onSubmit={onSubmit}
          initialValues={{
            title: initialData.title,
            pillarSlug: initialData.pillarSlug,
            questionSlug: initialData.questionSlug,
            videoId: initialData.Video.id,
            answer: initialData.answer,
            category: getVaultValue(),
          }}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <Field name="title">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      marginBottom="10px"
                      isDisabled={isSubmitting}
                      isInvalid={Boolean(
                        form.errors.title && form.touched.title
                      )}
                    >
                      <FormLabel fontSize="14px" p="0px" m="0px">
                        Title
                      </FormLabel>
                      <InputGroup>
                        <Input {...field} id="title" placeholder="Title" />
                      </InputGroup>
                      <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Spacer />
                <Spacer />
                <Field name="pillarSlug">
                  {({ field, form }: any) => (
                    <FormControl
                      marginBottom="10px"
                      isDisabled={isSubmitting}
                      isInvalid={
                        form.errors.pillarSlug && form.touched.pillarSlug
                      }
                    >
                      <FormLabel fontSize="14px" p="0px" m="0px">
                        Pillar
                      </FormLabel>
                      <InputGroup>
                        <CustomSelect
                          defaultValue={field.value}
                          name="pillarSlug"
                          options={pillarCategoryOptions}
                          onSelect={(input: any) => {
                            setFieldValue('pillarSlug', input)
                            fetchDailyVideos()
                            setFieldValue('questionSlug', '')
                            setFieldValue('videoId', '')
                            setFieldValue('answer', '')
                          }}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.pillarSlug}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Spacer />
                <Field name="questionSlug">
                  {({ field, form }: any) => (
                    <FormControl
                      marginBottom="10px"
                      isDisabled={isSubmitting}
                      isInvalid={
                        form.errors.questionSlug && form.touched.questionSlug
                      }
                    >
                      <FormLabel fontSize="14px" p="0px" m="0px">
                        Question
                      </FormLabel>
                      <InputGroup>
                        <CustomSelect
                          defaultValue={field.value}
                          name="questionSlug"
                          options={getQuestions(values.pillarSlug)}
                          onSelect={(input: string) => {
                            setFieldValue('questionSlug', input)
                            setFieldValue('videoId', '')
                            setFieldValue('answer', '')
                          }}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.questionSlug}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Spacer />
                <Field name="answer">
                  {({ field, form }: any) => (
                    <FormControl
                      marginBottom="10px"
                      isDisabled={isSubmitting}
                      isInvalid={form.errors.answer && form.touched.answer}
                    >
                      <FormLabel fontSize="14px" p="0px" m="0px">
                        Answer Type
                      </FormLabel>
                      <InputGroup>
                        <CustomSelect
                          defaultValue={field.value}
                          name="answer"
                          options={getAnswers(
                            values.pillarSlug,
                            values.questionSlug
                          )}
                          onSelect={(input: string) =>
                            setFieldValue('answer', input)
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>{form.errors.answer}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Spacer />
                <Field name="category">
                  {({ field, form }: any) => (
                    <FormControl
                      marginBottom="10px"
                      isDisabled={isSubmitting}
                      isInvalid={form.errors.category && form.touched.category}
                    >
                      <FormLabel fontSize="14px" p="0px" m="0px">
                        Vault Category
                      </FormLabel>
                      <InputGroup>
                        <CustomSelect
                          defaultValue={field.value}
                          name="category"
                          options={getCategories}
                          onSelect={(input: string) =>
                            setFieldValue('category', input)
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.category}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Spacer />
                <Field name="videoId">
                  {({ field, form }: any) => (
                    <FormControl
                      marginBottom="10px"
                      isDisabled={isSubmitting}
                      isInvalid={form.errors.videoId && form.touched.videoId}
                    >
                      <FormLabel fontSize="14px" p="0px" m="0px">
                        Video
                      </FormLabel>
                      <InputGroup>
                        <CustomSelect
                          defaultValue={field.value}
                          name="videoId"
                          options={getVideoOptions(values.category)}
                          onSelect={(input: string) =>
                            setFieldValue('videoId', input)
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>{form.errors.videoId}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Spacer />
                <Box display="flex" mt="10">
                  <Box className="trash__container">
                    <DeleteIcon
                      cursor="pointer"
                      className="trash__delete-icon"
                      disabled={loading}
                      borderRadius="8px"
                      id="video-enabled-button"
                      color="#fff"
                      onClick={() => handleDeleteConfirmation(selectedRow)}
                    />
                  </Box>

                  <Button
                    width="100%"
                    ml="10"
                    loadingText="Please wait"
                    type="submit"
                    isFullWidth
                    borderRadius="8px"
                    color="white"
                    colorScheme="fitgmrPurple"
                    isLoading={isSubmitting}
                  >
                    <Text pt="1">Save</Text>
                  </Button>
                </Box>
                <Spacer />
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>

      <ConfirmModal
        shouldOpen={openConfirmModal}
        body={modalBody}
        handleAction={() => {
          handleConfirmDelete(selectedRow?.id)
          setOpenConfirmModal(false)
        }}
        handleClose={() => setOpenConfirmModal(false)}
      />
    </>
  )
}

export default RecommendationUpdateForm
