import { gql } from '@apollo/client'

const getTeam = gql`
  query GetTeam(
    $where: TeamWhereUniqueInput!
    $usersWhere: UserWhereInput
    $usersOrderBy: [UserOrderByWithRelationInput!]
    $usersTake: Int
    $usersSkip: Int
  ) {
    team(where: $where) {
      id
      name
      description
      avatar
      game
      createdAt
      updatedAt
      ownerId
      TeamOwner {
        id
        email
        fullName
        username
        birthGender
        enabled
        role
        createdAt
        updatedAt
      }
      users(
        where: $usersWhere
        orderBy: $usersOrderBy
        take: $usersTake
        skip: $usersSkip
      ) {
        id
        usedDiscord
        email
        fullName
        username
        birthGender
        enabled
        role
        createdAt
        updatedAt
        Seat {
          id
          userId
          active
          teamId
        }
      }
      TeamInvites {
        id
        createdAt
        updatedAt
        inviteeEmail
        inviteeName
        role
        status
        inviterUserId
      }
      Seats {
        id
        userId
        teamId
        subscriptionId
        active
        User {
          id
          role
        }
      }
      coaches {
        id
        email
        username
        enabled
        role
        createdAt
        updatedAt
        Seat {
          id
          userId
          active
          teamId
        }
      }
    }
  }
`

export const getTeamWithStatsMethod = 'team'

const getTeamWithStats = gql`
  query GetTeam(
    $where: TeamWhereUniqueInput!
    $usersWhere: UserWhereInput
    $usersOrderBy: [UserOrderByWithRelationInput!]
    $usersTake: Int
    $usersSkip: Int
    $currentDate: String
    $questionSlug: String
  ) {
    ${getTeamWithStatsMethod}(where: $where) {
      id
      name
      description
      avatar
      game
      createdAt
      updatedAt
      TeamOwner{
        id
        coachSubscription
      }
      users(
        where: $usersWhere
        orderBy: $usersOrderBy
        take: $usersTake
        skip: $usersSkip
      ) {
        id
        usedDiscord
        email
        fullName
        username
        birthGender
        enabled
        role
        avatar
        createdAt
        updatedAt
        answers(where: {date: { equals: $currentDate }, questionSlug: { equals: $questionSlug }})  {
          questionSlug
          score
          id
          value
        }
        pillarStatistics(where: { date: { equals: $currentDate } }) {
          completenessScore
          date
          pillarSlug
        }
        score(date: $currentDate) {
          score
          prevDayScore
          todayScore

          pillarsIsolatedScore {
            score
            pillarSlug
          }
          extraPillarsTodayScores {
            pillarSlug
            score
          }
          pillars {
            avgCompletion
          }

        }
      }
      TeamInvites {
        id
        createdAt
        updatedAt
        inviteeEmail
        inviteeName
        role
        status
      }
    }
  }
`

const getAccountStatus = gql`
  query GetAccountStatus($userWhere: UserWhereUniqueInput!) {
    user(where: $userWhere) {
      id
      enabled
      role
      Team {
        id
      }
      coachedTeams {
        id
        name
      }
    }
  }
`

const getTeamCodes = gql`
  query GetTeamCodes($teamId: String!) {
    getTeamCodes(teamId: $teamId) {
      id
      code
      teamId
      used
      accepted
      email
      userId
      inviteId
    }
  }
`

const teamsByOwner = gql`
  query GetTeamsByOwner($ownerId: String!) {
    teamsByOwner(ownerId: $ownerId) {
      id
      name
      game
      avatar
      notificationCount
    }
  }
`
export const coachKey = 'me'
const teamsByCoach = gql`
  query Me {
    ${coachKey} {
      id
      coachedTeams {
        id
        name
        game
        avatar
      }
    }
  }
`

const verifyTeamSubscription = gql`
  query verifyTeamSubscription($teamId: String!) {
    verifyTeamSubscription(teamId: $teamId) {
      id
      status
    }
  }
`

const teamSeatsSubscriptions = gql`
  query teamSeatsSubscriptions($teamId: String!) {
    teamSeatsSubscriptions(teamId: $teamId) {
      isDefaultSeat
      userId
      id
      active
      User {
        id
        fullName
        email
        createdAt
        role
        username
        enabled
        usedDiscord
      }
    }
  }
`

const getSelectedTeam = gql`
  query getSelectedTeam {
    getSelectedTeam {
      id
      Team {
        id
        name
        description
        avatar
        game
        createdAt
        updatedAt
        ownerId
      }
    }
  }
`

const getReceivedInvite = gql`
  query receivedInviteByTeam($teamId: String!) {
    receivedInviteByTeam(teamId: $teamId) {
      id
      TeamInviter {
        id
        fullName
        avatar
      }
      Team {
        id
        name
        description
        avatar
        game
      }
    }
  }
`

export const TEAM_QUERIES = {
  GET_TEAM: getTeam,
  USER_EXISTS: getAccountStatus,
  GET_TEAM_WITH_STATS: getTeamWithStats,
  GET_TEAM_CODES: getTeamCodes,
  GET_TEAMS_BY_OWNER: teamsByOwner,
  VERIFY_TEAM_SUBSCRIPTION: verifyTeamSubscription,
  TEAM_SEATS_SUBSCRIPTION: teamSeatsSubscriptions,
  GET_SELECTED_TEAM: getSelectedTeam,
  GET_TEAMS_BY_COACH: teamsByCoach,
  GET_RECEIVED_INVITE: getReceivedInvite,
}
