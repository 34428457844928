import React, { useEffect } from 'react'
import {
  Box,
  Heading,
  Text,
  Container,
  Flex,
  Img,
  Center,
  Link,
} from '@chakra-ui/react'

import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { capitalize } from 'lodash'

import Page from '../../components/shared/ui/page/Page'
import { SHARE_SCORE_MUTATIONS } from '../../components/share-fg-score/mutations/shareFGScore.mutations'

import './ReportsLanding.scss'

const ReportsLanding: React.FC = () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const shareId = queryParams.get('shareId')
  const acceptance = queryParams.get('acceptance')
  const userName = queryParams.get('name') || 'user'

  const [activateReports] = useMutation(SHARE_SCORE_MUTATIONS.ACTIVATE, {
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  useEffect(() => {
    if (shareId) {
      activateReports({
        variables: {
          shareId,
          acceptance: acceptance === 'accepted' ? 'ACCEPTED' : 'REJECTED',
        },
      })
    }
  }, [])

  return (
    <Page>
      <Center>
        <Flex
          direction="column"
          align="center"
          justify="center"
          w={[350, 500, 1000]}
        >
          <Box as="header" width="100%" py="6" bg="#1a1a1a">
            <Flex justify="center">
              <Img
                src="https://fitgmr-prod.s3.us-west-2.amazonaws.com/adminFile-78b219b2-874a-4d4d-8572-77d6926ab5fb.png"
                width="200px"
                border="10px"
                alt="FITGMR logo"
              />
            </Flex>
          </Box>
          <Container maxW="5xl" bg="#1d1d1d">
            <Flex justify="center">
              <Box mt="8" width="2xl">
                {acceptance === 'accepted' ? (
                  <>
                    <Text className="report-landing">
                      {`
                                  You will receive Daily Updates with ${capitalize(
                                    userName
                                  )}'s FITGMR 
                                  Score showing progress, achievements and areas for improvement in the 
                                  FITGMR 5 Pillars of Health.`}
                    </Text>
                    <Heading as="h2" size="lg" mb="4">
                      {`How we work ${capitalize(userName)}'s score out`}
                    </Heading>
                    <Text className="report-landing">
                      {`${capitalize(
                        userName
                      )}'s FITGMR Score is an amalgamation of ${capitalize(
                        userName
                      )}'s performance in the 5
                            Pillars of Health and ${userName}'s consistency inputting data on a daily
                            basis. We look at the healthy habits you are building over time in
                            Sleep, Physical Maintenance, Nutrition, Mental Conditioning and
                            Lifestyle and reward long streaks of using the app.  ${capitalize(
                              userName
                            )}'s score will
                            always be a running 15-day average of past Daily Scores, and
                            certain responses have more weight than others based on what we know
                            about health and cognitive performance.`}
                    </Text>
                  </>
                ) : (
                  <>
                    <Heading as="h2" size="lg" mb="4">
                      {`Unsubscribe from ${capitalize(userName)}'s reports`}
                    </Heading>
                    <Text className="report-landing">
                      {`
                    You have successfully unsubscribed from FITGMR Daily Reports. 
                    If you would like to re-subscribe, please contact our support team.`}
                    </Text>
                  </>
                )}

                <Text color="gray" mb="2">
                  Happy gaming!
                </Text>
                <Text>FITGMR Support Team</Text>
                <Box borderWidth="1px" borderRadius="md" p="5" my="10">
                  <Text fontSize="lg" as="b">
                    Need help?
                  </Text>
                  <Text color="gray" fontSize={[14, 15, 16]}>
                    Something look wrong? Please contact our{' '}
                    <Link color="brand.50" href="mailto:support@example.com">
                      support team
                    </Link>
                    .
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Container>
          <Container maxW="5xl" bg="#272727" pb="20">
            <Flex justify="space-evenly" align="flex-start" mt="8">
              <Box maxW="sm">
                <Text fontWeight="bold" fontSize={[18, 20, 22]} color="#a0a0a0">
                  FOLLOW
                </Text>
                <Text mb="4" color="#a0a0a0" fontSize={[13, 14, 16]}>
                  We’re always looking to level up more gamers, boosting your
                  health and performance every day!
                </Text>
                <Flex>
                  <Link
                    bg="#1a1a1a"
                    href="https://www.facebook.com/FitGMR"
                    p="2"
                    borderRadius="md"
                    mr="4"
                  >
                    <Img
                      src="https://fitgmr-prod.s3.us-west-2.amazonaws.com/adminFile-c95ebf6d-8878-4dd0-aae2-526ee8de5303.png"
                      alt="Facebook"
                      width="4"
                    />
                  </Link>
                  <Link
                    bg="#1a1a1a"
                    href="https://twitter.com/FitGmr"
                    p="2"
                    borderRadius="md"
                    mr="4"
                  >
                    <Img
                      src="https://fitgmr-prod.s3.us-west-2.amazonaws.com/adminFile-c44553ee-b9c2-48ef-bbff-0fe447be6cdb.png"
                      alt="Twitter"
                      width="4"
                    />
                  </Link>
                  <Link
                    bg="#1a1a1a"
                    href="https://www.instagram.com/fitgmr/"
                    p="2"
                    borderRadius="md"
                    mr="4"
                  >
                    <Img
                      src="https://fitgmr-prod.s3.us-west-2.amazonaws.com/adminFile-2e4d24cd-d492-41f5-8f48-77b8c7dc8ed6.png"
                      alt="Instagram"
                      width="4"
                    />
                  </Link>
                  <Link
                    bg="#1a1a1a"
                    href="https://www.linkedin.com/company/fitgmr/"
                    p="2"
                    borderRadius="md"
                    mr="4"
                  >
                    <Img
                      src="https://fitgmr-prod.s3.us-west-2.amazonaws.com/adminFile-892a726a-227e-4317-90d0-5bd66f3877d7.png"
                      alt="LinkedIn"
                      width="4"
                    />
                  </Link>
                </Flex>
              </Box>

              <Box>
                <Text fontWeight="bold" fontSize={[18, 20, 22]} color="#a0a0a0">
                  CONTACT
                </Text>
                <Flex>
                  <Link
                    href="mailto:support@example.com"
                    mr="2"
                    color="#a0a0a0"
                    fontSize={[13, 14, 16]}
                  >
                    support@fitgmr.gg
                  </Link>
                </Flex>
              </Box>
            </Flex>
          </Container>
        </Flex>
      </Center>
    </Page>
  )
}

export default ReportsLanding
