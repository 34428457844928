/* eslint-disable camelcase */
export interface IVideo {
  id: string
  title?: string
  url?: string
  thumbnailUrl?: string
  selectedThumbnail?: string
  description?: string
  category?: string
  pillarCategory?: string
  duration?: number
  availableFrom?: string | null
  VideoSeries?: IVideoSeries
}

export interface IVideoSeries {
  id: string
  name: string
  description?: string
  coverImage?: string
  basic: boolean
}

export enum FitGmrVaultPillars {
  nutrition = 'nutrition',
  mental = 'mental',
  lifestyle = 'lifestyle',
}

export enum FitGmrVaultCategories {
  c9tg = 'c9tg',
  meditation = 'meditation',
  workouts = 'workouts',
  yoga = 'yoga',
  quick_drills = 'quick_drills',
  breathing = 'breathing',
  sleep = 'sleep',
  gss = 'gss',
  beyond_the_game = 'beyond_the_game',
  eye_health = 'eye_health',
  fitgmr_university = 'fitgmr_university',
}

export type IVaultState = {
  [key in FitGmrVaultPillars | FitGmrVaultCategories | string]: Array<IVideo>
}

export type IVaultCategory = {
  title: string
  type: FitGmrVaultPillars | FitGmrVaultCategories
}

export const vaultCategories: IVaultCategory[] = [
  {
    title: 'Getting Started',
    type: FitGmrVaultCategories.gss,
  },
  {
    title: 'Workouts',
    type: FitGmrVaultCategories.workouts,
  },

  {
    title: 'Mental Conditioning',
    type: FitGmrVaultPillars.mental,
  },
  {
    title: 'Nutrition',
    type: FitGmrVaultPillars.nutrition,
  },

  {
    title: 'Yoga',
    type: FitGmrVaultCategories.yoga,
  },
  {
    title: 'Quick Drills',
    type: FitGmrVaultCategories.quick_drills,
  },
  {
    title: 'Meditation',
    type: FitGmrVaultCategories.meditation,
  },
  {
    title: 'Sleep',
    type: FitGmrVaultCategories.sleep,
  },
  {
    title: 'Lifestyle',
    type: FitGmrVaultPillars.lifestyle,
  },
  {
    title: 'Beyond the Game',
    type: FitGmrVaultCategories.beyond_the_game,
  },
  {
    title: 'Breathing',
    type: FitGmrVaultCategories.breathing,
  },
  {
    title: 'Training Grounds',
    type: FitGmrVaultCategories.c9tg,
  },

  {
    title: 'Eye Health',
    type: FitGmrVaultCategories.eye_health,
  },
  {
    title: 'FITGMR University',
    type: FitGmrVaultCategories.fitgmr_university,
  },
]
// same as in mobile vault section
export default function getVaultState(videos: Array<IVideo>): IVaultState {
  const vault: { [key in string]: Array<IVideo> } = {
    gss: videos.filter((v) => v.VideoSeries?.basic),
  }

  let videosReduced = videos
    .filter((v) => !v.VideoSeries?.basic)
    .sort((a, b) => a.title?.localeCompare(b.title || '') as any)

  const organizeVaultBy = (videoKey: keyof IVideo) => (key: string) => {
    if (key === 'gss' || videosReduced.length === 0) return
    vault[key] = videosReduced.filter(
      (video) => video[videoKey] === key.toUpperCase()
    )
    videosReduced = videosReduced.filter(
      (video) => video[videoKey] !== key.toUpperCase()
    )
  }

  Object.keys(FitGmrVaultCategories).forEach(organizeVaultBy('category'))
  Object.keys(FitGmrVaultPillars).forEach(organizeVaultBy('pillarCategory'))

  return vault as IVaultState
}
