import { AspectRatio, Box, Grid, GridItem, Image, Text } from '@chakra-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { HiOutlinePlay } from 'react-icons/hi'
import {
  CategoryOption,
  PillarQuestionCategory,
  PillarVideoCategories,
  VideoRecommendationType,
} from '../../components/video-recommendation/Video.recommendation.types'
import { PillarCategory } from '../../components/content/store/content.types'
import { Table } from '../../components/shared/ui/table'
import SearchAndFilter from './SearchAndFilter'
import placeholder from '../../assets/images/placeholder.svg'
import UpdateRecommendation from './UpdateRecommendation'
import { RECOMMENDATION_QUERIES } from '../../components/video-recommendation/queries/recommendation.queries'
import useMediaModal from '../../components/shared/ui/media-modal/useMediaModal'
import { RECOMMENDATION_MUTATIONS } from '../../components/video-recommendation/mutations/recommendation.mutations'

const VideoRecommendation: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState(null)
  const [selected, setSelected] = useState<string[]>([])
  const { setMediaData, onOpen, MediaModal } = useMediaModal()
  // Pagination
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const {
    loading: dataLoading,
    data,
    refetch,
  } = useQuery(RECOMMENDATION_QUERIES.GET_RECOMMENDATION, {
    variables: {
      take: rowsPerPage,
      skip: (page - 1) * rowsPerPage,
    },
  })

  const pageCount = Math.ceil(
    (data?.getRecommendations?.count || 40) / rowsPerPage
  )

  const [deleteRecommendation] = useMutation(
    RECOMMENDATION_MUTATIONS.DELETE_RECOMMENDATION,
    {
      onCompleted: () => {
        setSelectedRow(null)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const handleConfirmDelete = (id: string) => {
    deleteRecommendation({
      variables: {
        id,
      },
    })
  }

  const getPillarColor = (category: string) => {
    switch (category) {
      case PillarCategory.LIFESTYLE:
        return 'fitgmrBlue.50'
      case PillarCategory.MENTAL:
        return 'fitgmrRed.50'
      case PillarCategory.NUTRITION:
        return 'fitgmrGreen.50'
      case PillarCategory.PHYSICAL:
        return 'fitgmrOrange.50'
      case PillarCategory.SLEEP:
        return 'fitgmrPurple.50'
      default:
        return 'white'
    }
  }

  const flattenedOptions = PillarVideoCategories.flatMap(
    (pillar: PillarQuestionCategory) =>
      Object.values(pillar).flatMap((options: CategoryOption[]) =>
        options.map((option: CategoryOption) => ({
          activity: option.activity,
          label: option?.label || option.activity,
        }))
      )
  )

  const headers = [
    {
      id: 'title',
      title: 'Title',
      render: ({ title }: VideoRecommendationType) => (
        <Box width="100%">
          <Text fontWeight="bold" fontSize="14px">
            {title}
          </Text>
        </Box>
      ),
    },
    {
      id: 'pillarSlug',
      title: 'Pillar',
      render: ({ pillarSlug }: VideoRecommendationType) => {
        const colorScheme = getPillarColor(pillarSlug.toUpperCase())
        return (
          <Box width="100%">
            <Text fontWeight="bold" fontSize="14px" color={colorScheme}>
              {pillarSlug.toUpperCase()}
            </Text>
          </Box>
        )
      },
    },
    {
      id: 'questionSlug',
      title: 'Question',
      render: ({ questionSlug }: VideoRecommendationType) => {
        const question = flattenedOptions.find(
          (option) => option.activity === questionSlug
        )?.label
        return (
          <Box width="100%">
            <Text fontWeight="bold" fontSize="14px">
              {question?.replace('_', ' ').toUpperCase()}
            </Text>
          </Box>
        )
      },
    },
    {
      id: 'answer',
      title: 'Answer Type',
      render: ({ answer }: VideoRecommendationType) => (
        <Box width="100%">
          <Text fontWeight="bold" fontSize="14px">
            {answer.toUpperCase()}
          </Text>
        </Box>
      ),
    },
    {
      id: 'Videos',
      title: 'Video',
      render: ({ Video, title }: VideoRecommendationType) => {
        const { url, selectedThumbnail, thumbnailUrl } = Video
        return (
          <Box width="100%">
            <AspectRatio
              width="120px"
              maxWidth="150px"
              ratio={16 / 9}
              cursor="pointer"
              onClick={() => {
                setMediaData({ url, title })
                onOpen()
              }}
              padding={0}
            >
              <>
                <Text position="absolute" zIndex="1">
                  <HiOutlinePlay size="2.5rem" />
                </Text>
                <Image
                  objectFit="contain"
                  borderRadius="6px"
                  src={selectedThumbnail || thumbnailUrl}
                  fallbackSrc={placeholder}
                  alt="thumbnail image"
                />
              </>
            </AspectRatio>
          </Box>
        )
      },
    },
  ]
  return (
    <>
      <Grid templateColumns="repeat(5, 1fr)">
        {selectedRow && (
          <GridItem
            colSpan={1}
            className="contentDetailTransition"
            bgColor="#1C1C1D"
            rowSpan={3}
            margin={3}
            borderRadius={8}
            minHeight="950px"
            minWidth="450px"
          >
            <UpdateRecommendation
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              loading={false}
              handleConfirmDelete={handleConfirmDelete}
              refetch={refetch}
            />
          </GridItem>
        )}
        <GridItem
          colSpan={selectedRow ? 4 : 6}
          bgColor="#1C1C1D"
          marginTop={3}
          marginRight={3}
          marginLeft={!selectedRow ? 3 : 0}
          padding={5}
          borderRadius={8}
          maxHeight="150px"
        >
          <SearchAndFilter refetch={refetch} />
        </GridItem>
        <GridItem
          colSpan={selectedRow ? 4 : 6}
          bgColor="#1C1C1D"
          marginTop={3}
          marginRight={3}
          marginBottom={3}
          marginLeft={!selectedRow ? 3 : 0}
          padding={5}
          borderRadius={8}
        >
          <Table
            className="content-list-table"
            isLoading={dataLoading}
            showId={false}
            selectable={false}
            selected={selected}
            handleSelect={setSelected}
            headers={headers}
            items={data?.getRecommendations?.list || []}
            withPagination
            pageCount={pageCount}
            page={page}
            setPage={setPage}
            canNextPage={page < pageCount}
            canPreviousPage={page > 1}
            rowsPerPage={rowsPerPage}
            setPageSize={setRowsPerPage}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            sortKeys={['title', 'questionSlug', 'pillarSlug']}
          />
        </GridItem>
      </Grid>
      <MediaModal />
    </>
  )
}

export default VideoRecommendation
