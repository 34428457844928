import { gql } from '@apollo/client'

const login = gql`
  mutation Login($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      accessToken
      refreshToken
      expiresIn
      id
      groups
      user {
        id
        authId
        username
        fullName
        birthGender
        role
        enabled
        country
        email
        usedDiscord
        createdAt
        updatedAt
        coachSubscription
        Team {
          id
          name
          description
          avatar
          game
          createdAt
          updatedAt
        }
        Payments {
          id
          userId
          subscriptionConfigId
          productName
          productPrice
          paymentFrequency
          subscriptionId
          status
          method
        }
      }
    }
  }
`

const signUp = gql`
  mutation SignUp(
    $data: UserSignUpInput!
    $password: String!
    $confirmPassword: String!
  ) {
    signUpUser(
      data: $data
      password: $password
      confirmPassword: $confirmPassword
    ) {
      accessToken
      refreshToken
      expiresIn
      id
      groups
      user {
        id
        authId
        username
        fullName
        birthGender
        role
        enabled
        country
        email
        usedDiscord
        createdAt
        updatedAt
        Team {
          id
          name
          description
          avatar
          game
          createdAt
          updatedAt
        }
      }
    }
  }
`

const forgotPassword = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`

const forgotPasswordVerify = gql`
  mutation ForgotPasswordVerify($code: String!, $email: String!) {
    forgotPasswordVerify(code: $code, email: $email)
  }
`

const changePassword = gql`
  mutation ForgotPasswordFinal(
    $password: String!
    $code: String!
    $email: String!
  ) {
    forgotPasswordFinal(newPassword: $password, code: $code, email: $email)
  }
`

const refreshToken = gql`
  mutation RefreshAuthToken(
    $accessToken: String!
    $refreshToken: String!
    $expiresIn: Float!
  ) {
    refreshToken(
      accessToken: $accessToken
      refreshToken: $refreshToken
      expiresIn: $expiresIn
    ) {
      accessToken
      refreshToken
      expiresIn
      id
      groups
    }
  }
`

export const discordLoginMethod = 'logInUserWithDiscord'
const discordLogin = gql`
  mutation DiscordLogin($code: String!, $redirect_uri: String) {
    ${discordLoginMethod}(code: $code, redirect_uri: $redirect_uri) {
      accessToken
      refreshToken
      expiresIn
      id
      groups
      user {
        id
        authId
        username
        fullName
        birthGender
        actualGender
        country
        email
        usedDiscord
        createdAt
        updatedAt
        avatar
        subscription
        birthDate
        weight
        height
        timeToBed
        timeOutOfBed
        hoursAtDesk
        gameRoutines
        sleepRoutines
        waistCircumference
        hipCircumference
        healthyMeals
        preferMetricUnits
        waterIntakePreference
        onboardingStatus
        shownGettingStartedCompleted
        subscription
        appSubscriptionReason
        coachSubscription
        Payments {
          productId
          subscriptionId
          productName
          productPrice
          paymentFrequency
          status
          method
          endTrialAt
          endSubscriptionAt
          createdAt
          SubscriptionConfig {
            id
            name
            createdAt
          }
          User{
            email
            username
            fullName
          }
        }
      }
    }
  }
`

export const applyCouponMethod = 'applyCouponToEmail'
const applyCoupon = gql`
  mutation ApplyCoupon($email: String!, $couponId: String!) {
    ${applyCouponMethod}(email: $email, couponId: $couponId)
  }
`

export const startTrialMethod = 'startTrial'
const startTrial = gql`
  mutation StartTrial($where: SubscriptionConfigWhereUniqueInput!) {
    ${startTrialMethod}(where: $where) {
      subscriptionId
      clientSecret      
    }
  }
`

export const upgradeSubscriptionMethod = 'upgradeSubscription'
const upgradeSubscription = gql`
  mutation UpgradeSubscription($where: SubscriptionConfigWhereUniqueInput!, $couponId: String, $teamId: String, $quantity: Float, $alternatePayment: Boolean) {
    ${upgradeSubscriptionMethod}(where: $where, couponId: $couponId, teamId: $teamId, quantity: $quantity, alternatePayment: $alternatePayment) {
      subscriptionId
      clientSecret
      amountDue
      status
    }
  }
`

export const cancelSubscriptionMethod = 'cancelSubscription'
const cancelSubscription = gql`
  mutation CancelSubscription($where: SubscriptionConfigWhereUniqueInput!) {
    ${cancelSubscriptionMethod}(where: $where) 
  }
`
export const setupIntentMethod = 'setupPaymentMethod'
const setupIntent = gql`
  mutation SetupPaymentMethod {
    ${setupIntentMethod} {
      subscriptionId
      clientSecret     
    }
  }
`

export const checkCouponMethod = 'checkCoupon'
const checkCoupon = gql`
  query CheckCoupon($couponId: String!, $product: String) {
    ${checkCouponMethod}(couponId: $couponId, product: $product) {
      id
      object
      created
      duration
      livemode
      name
      percent_off
      times_redeemed
      valid
    } 
  }
`

export const AUTH_MUTATIONS = {
  LOGIN: login,
  SIGNUP: signUp,
  FORGOT_PASSWORD: forgotPassword,
  FORGOT_PASSWORD_VERIFY: forgotPasswordVerify,
  CHANGE_PASSWORD: changePassword,
  REFRESH_TOKEN: refreshToken,
  LOGIN_DISCORD: discordLogin,
  APPLY_COUPON: applyCoupon,
  START_TRIAL: startTrial,
  UPGRADE_SUBSCRIPTION: upgradeSubscription,
  CANCEL_SUBSCRIPTION: cancelSubscription,
  SETUP_INTENT: setupIntent,
  CHECK_COUPON: checkCoupon,
}
