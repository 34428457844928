import React, { useCallback, useEffect, useRef } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  VStack,
  FormControl,
  FormLabel,
  Select,
  Button,
  Input,
  Image,
} from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import {
  CategoryOption,
  PillarQuestionCategory,
  PillarVideoCategories,
} from '../../components/video-recommendation/Video.recommendation.types'
import { RECOMMENDATION_MUTATIONS } from '../../components/video-recommendation/mutations/recommendation.mutations'
import getVaultState, { IVaultCategory, IVideo } from './videosGroupToVault'
import { useVaultCategories } from '../../hooks/useVaultCategories'

type ActivityType = { activity: string; label: string }

const AddRecommendationModal: React.FC<{
  open: boolean
  closeModal: () => void
}> = ({ open, closeModal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ isOpen: open })
  const { getVaultCategories, pillarVideoResponse, categories } =
    useVaultCategories()
  const btnRef = useRef(null)

  const handleCloseModal = useCallback(() => {
    closeModal()
  }, [closeModal])

  const [addRecommendation] = useMutation(
    RECOMMENDATION_MUTATIONS.CREATE_RECOMMENDATION,
    {
      onCompleted: () => {
        handleCloseModal()
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        formik.resetForm()
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      title: '',
      pillarSlug: '',
      questionSlug: '',
      answer: '',
      videoId: '',
      category: '',
    },
    onSubmit: (values) => {
      addRecommendation({
        variables: {
          data: {
            title: values.title,
            videoId: values.videoId,
            pillarSlug: values.pillarSlug,
            questionSlug: values.questionSlug,
            answer: values.answer,
          },
        },
      })
    },
  })

  useEffect(() => {
    if (open) {
      getVaultCategories()
      onOpen()
    } else {
      onClose()
    }
  }, [open])

  const getActivities = (selectedKey: string): ActivityType[] | [] => {
    const selectedObject = PillarVideoCategories.find(
      (obj) => Object.keys(obj)[0] === selectedKey
    )

    if (selectedObject) {
      const activitiesArray = selectedObject[selectedKey].map(
        (activityObj: CategoryOption) => ({
          activity: activityObj.activity,
          label: activityObj?.label || activityObj.activity,
        })
      )
      return activitiesArray
    }

    return []
  }

  const getAnswers = (selectedKey: string, selectedActivity: string) => {
    const selectedObject = PillarVideoCategories.find(
      (obj) => Object.keys(obj)[0] === selectedKey
    )

    if (selectedObject) {
      const selectedActivityObject = selectedObject[selectedKey].find(
        (activityObj: any) => activityObj.activity === selectedActivity
      )
      return selectedActivityObject?.options || []
    }

    return []
  }

  const getVideoOptions = (vaultCategory: string): IVideo[] | [] => {
    if (vaultCategory && pillarVideoResponse?.videos)
      return getVaultState(pillarVideoResponse?.videos)[vaultCategory]
    return []
  }

  const showPreviewVideo = () => {
    const video = pillarVideoResponse?.videos.find(
      (video: any) => video.id === formik.values.videoId
    )
    if (video) {
      return (
        <Image
          maxWidth="350px"
          objectFit="contain"
          borderRadius="10px"
          src={video.selectedThumbnail || video.thumbnailUrl}
          alt="thumbnail image"
        />
      )
    }
    return null
  }

  return (
    <>
      <Modal
        onClose={handleCloseModal}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        scrollBehavior="outside"
        size="lg"
      >
        <ModalOverlay />
        <ModalContent
          bgColor="#272727"
          color="white"
          border="none"
          borderRadius="4px"
          className="team-invite"
        >
          <ModalHeader textAlign="center">Add Video Recommendation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w="100%" align="center" justify="center" direction="column">
              <div />
              <form onSubmit={formik.handleSubmit}>
                <VStack spacing={4} align="flex-start">
                  <FormControl w="350px">
                    <FormLabel htmlFor="title">Title</FormLabel>
                    <div>
                      <Input
                        id="title"
                        name="title"
                        type="title"
                        variant="filled"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        maxLength={16}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          fontSize: '12px',
                        }}
                      >
                        {formik.values.title.length}/{16}
                      </div>
                    </div>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="pillar">Pillar</FormLabel>
                    <Select
                      id="pillarSlug"
                      name="pillarSlug"
                      type="pillarSlug"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.pillarSlug}
                      disabled={!formik.values.title}
                      placeholder="Select Pillar"
                    >
                      {PillarVideoCategories.map(
                        (pillar: PillarQuestionCategory) => (
                          <option key={Object.keys(pillar)[0] as string}>
                            {Object.keys(pillar)[0]}
                          </option>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="questionSlug">Question</FormLabel>
                    <Select
                      id="questionSlug"
                      name="questionSlug"
                      type="questionSlug"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.questionSlug}
                      disabled={!formik.values.pillarSlug}
                      placeholder="Select Question"
                    >
                      {getActivities(formik.values.pillarSlug).map(
                        (activity: ActivityType) => (
                          <option
                            key={activity.activity}
                            value={activity.activity}
                          >
                            {activity.label}
                          </option>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="answer">Answer Type</FormLabel>
                    <Select
                      id="answer"
                      name="answer"
                      type="answer"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.answer}
                      disabled={!formik.values.questionSlug}
                      placeholder="Select Answer Type"
                    >
                      {getAnswers(
                        formik.values.pillarSlug,
                        formik.values.questionSlug
                      ).map((activity: string) => (
                        <option key={activity}>{activity}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="category">Vault Category</FormLabel>
                    <Select
                      id="category"
                      name="category"
                      type="category"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.category}
                      disabled={!formik.values.title}
                      placeholder="Select Category"
                    >
                      {categories.map((category: IVaultCategory) => (
                        <option key={category.type} value={category.type}>
                          {category.title}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="video">Video Link</FormLabel>
                    <Select
                      id="videoId"
                      name="videoId"
                      type="videoId"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.videoId}
                      disabled={!formik.values.category}
                      placeholder="Select Video"
                    >
                      {getVideoOptions(formik.values.category).map(
                        (video: IVideo) => (
                          <option key={video.id} value={video.id}>
                            {video.title}
                          </option>
                        )
                      )}
                    </Select>
                  </FormControl>
                  {formik.values.videoId && showPreviewVideo()}

                  <Button
                    type="submit"
                    colorScheme="purple"
                    width="full"
                    disabled={!formik.values.videoId}
                  >
                    Save
                  </Button>
                </VStack>
              </form>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddRecommendationModal
