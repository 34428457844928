/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Center,
  Flex,
  Box,
  Button,
  Text,
  Divider,
  Radio,
  RadioGroup,
  CircularProgress,
  Tag,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useLazyQuery, useMutation } from '@apollo/client'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import './Pricing.scss'
import { ReactComponent as CircleCheck } from '../../assets/images/circle-check.svg'
import { ReactComponent as FitgmrShape } from '../../assets/images/fitgmr-shape.svg'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'
import {
  AUTH_MUTATIONS,
  upgradeSubscriptionMethod,
} from '../../components/auth/mutations/auth.mutations'
import {
  AUTH_QUERIES,
  userKey,
} from '../../components/auth/mutations/auth.queries'
import {
  setUpdateUserGroups,
  setTokenRefreshRequest,
  setUpdateUser,
  setLogoutSuccess,
} from '../../components/auth/store/auth.actions'
import {
  selectTokens,
  selectIsAuthenticated,
} from '../../components/auth/store/auth.selectors'
import { AuthPayload, IUser } from '../../components/auth/store/auth.types'

import Page from '../../components/shared/ui/page/Page'
import { PricingConfig, pricingConfig } from '../../helpers/pricing'
import { SubscriptionType } from '../../components/auth/types'
import { appearance } from '../../helpers/constants'

import PaymentSuccessPrompt from '../../components/subscription/ui/PaymentSuccessPrompt'
import PaymentFailurePrompt from '../../components/subscription/ui/PaymentFailurePrompt'
import LoadingModal from '../../components/subscription/ui/LoadingModal'
import CheckoutModal from '../../components/subscription/ui/CheckoutModal'
import CouponCode from '../CouponCode/CouponCode'

import { config } from '../../config'

const { stripePublishableKey } = config
const stripePromise = loadStripe(stripePublishableKey)

const Pricing: React.FC = () => {
  const isAuthenticated: boolean = useSelector(selectIsAuthenticated)
  const [subscriptionConfigs, setSubscriptionConfigs] = useState<any>([])
  const [plan, setPlan] = useState<PricingConfig>()
  const [planType, setPlanType] = useState<string>('yearly')
  const [checkoutModalOpen, setCheckoutModalOpen] = useState<boolean>(false)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [amountDue, setAmountDue] = useState<number>()
  const [isDonePromptOpen, setIsDonePromptOpen] = useState<boolean>(false)
  const [isFailurePromptOpen, setIsFailurePromptOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [planKeyType, setPlanKeyType] = useState<string>()
  const route = useHistory()
  const dispatch = useDispatch()
  const tokens: AuthPayload = useSelector(selectTokens)
  const [isCouponCodeOpen, setIsCouponCodeOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!isAuthenticated) {
      route.replace('/auth/login')
    }
  }, [isAuthenticated])

  const [getUser] = useLazyQuery(AUTH_QUERIES.GET_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted(userData: { [userKey]: IUser }) {
      dispatch(setUpdateUser(userData[userKey]))
    },
    onError() {},
  })

  const continueToDashboard = () => {
    dispatch(setUpdateUserGroups({ groups: ['/DIRECTOR'], role: 'DIRECTOR' }))
    dispatch(
      setTokenRefreshRequest({
        accessToken: tokens.accessToken!,
        expiresIn: tokens.expiresIn!,
        refreshToken: tokens.refreshToken!,
      })
    )
    getUser()
    route.push('/home/my-team/new')
  }

  const paymentSuccess = async () => {
    setIsDonePromptOpen(true)
  }

  const paymentFailure = () => {
    setIsFailurePromptOpen(true)
  }

  const closeModal = () => {
    setCheckoutModalOpen(false)
    setIsCouponCodeOpen(false)
  }

  const [upgradeSubscription] = useMutation(
    AUTH_MUTATIONS.UPGRADE_SUBSCRIPTION,
    {
      onCompleted: (tData) => {
        setClientSecret(tData[upgradeSubscriptionMethod].clientSecret)
      },
      onError: () => {
        paymentFailure()
      },
    }
  )

  const [refetchMutation, { data, loading }] = useLazyQuery(
    AUTH_QUERIES.GET_SUBSCRIPTIONS_STRIPE,
    {
      fetchPolicy: 'network-only',
    }
  )

  const createSubscription = (coupon: any) => {
    subscriptionConfigs?.forEach((item: SubscriptionType) => {
      if (item.name === planKeyType) {
        upgradeSubscription({
          variables: {
            where: { id: item.id },
            couponId: coupon,
            quantity: 1,
          },
        }).then((res) => {
          const amount = res.data[upgradeSubscriptionMethod].amountDue
          setAmountDue(amount)
          if (amount === 0) {
            setIsCouponCodeOpen(false)
            paymentSuccess()
          } else {
            setCheckoutModalOpen(true)
          }

          setIsLoading(false)
        })
      }
    })
  }

  const handleChoosePlan = (plan: PricingConfig) => {
    setPlan(plan)
    setPlanKeyType(planType === 'yearly' ? `${plan.key} Yearly` : plan.key)
    setIsCouponCodeOpen(true)
  }

  const handleCouponCodeSubmit = (coupon: any) => {
    setIsLoading(true)
    createSubscription(coupon)
  }

  const handleCouponCodeSkip = () => {
    setIsLoading(true)
    createSubscription(null)
  }

  useEffect(() => {
    refetchMutation()
  }, [])

  useEffect(() => {
    if (data?.subscriptionConfigs) {
      setSubscriptionConfigs(data.subscriptionConfigs)
    }
  }, [data])

  const changePlanType = (event: any) => {
    setPlanType(event.target.value)
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  if (loading) {
    return (
      <Page>
        <Center h="100vh" color="GrayText" bg="#171717">
          <CircularProgress isIndeterminate color="brand" />
        </Center>
      </Page>
    )
  }

  return (
    <Page>
      <FitgmrShape className="fitgmr-shape" />
      <Box className="header">
        <Logo className="logo" />
        <Flex
          bg="#171717"
          pt={1}
          pr={2}
          justifyContent="end"
          className="button-container"
        >
          <Button
            onClick={() => dispatch(setLogoutSuccess())}
            className="sign-out-button"
          >
            Sign Out
          </Button>
        </Flex>
      </Box>
      <Center h="100vh" color="GrayText" bg="#171717" className="body">
        <Flex
          justifyContent="center"
          alignItems="start"
          width={{ base: '100%', lg: '80%' }}
          className="content-container"
        >
          {pricingConfig.map((pricing: any) => (
            <Box
              key={pricing.title}
              p={5}
              mx={4}
              width="40%"
              className="option-box"
            >
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                height="full"
              >
                <Flex
                  flexDirection="column"
                  alignItems="start"
                  className="box-title"
                >
                  <Text fontSize="xl" color="white">
                    Team Dashboard
                  </Text>
                  <Text as="b" fontSize="3xl" color="white">
                    {pricing.title}
                  </Text>
                </Flex>
                <Divider my={2} width="80%" />
                <Flex spacing={3} flexDirection="column" mb={2}>
                  {pricing.features.map((feature: string) => (
                    <Flex
                      key={feature}
                      fontWeight="light"
                      color="#bfbfbf"
                      fontSize="lg"
                      my={4}
                      pl={2}
                      className="description-item"
                    >
                      <Box className="check-container">
                        <CircleCheck className="circle-check" />
                      </Box>
                      <Text ml={2}>{feature}</Text>
                    </Flex>
                  ))}
                </Flex>
                <RadioGroup defaultValue="yearly">
                  <Flex className="price-options">
                    <Radio
                      colorScheme="brand"
                      value="monthly"
                      onChange={changePlanType}
                      mr={8}
                    >
                      <Flex>
                        {pricing?.previousPrice && (
                          <Flex alignItems="end" justifyContent="center" pr={2}>
                            <Text
                              color="white"
                              fontSize="3xl"
                              as="del"
                              style={{ textDecorationColor: '#a541f4' }}
                            >
                              {pricing.previousPrice.monthly}
                            </Text>
                            <Text color="#9e9e9e" fontSize="sm" pb={2}>
                              /month
                            </Text>
                          </Flex>
                        )}
                        <Flex alignItems="end" justifyContent="center">
                          <Text color="white" fontSize="3xl">
                            {pricing.price.monthly}
                          </Text>
                          <Text color="#9e9e9e" fontSize="sm" pb={2}>
                            /month
                          </Text>
                        </Flex>
                      </Flex>
                    </Radio>

                    <Radio
                      colorScheme="brand"
                      value="yearly"
                      onChange={changePlanType}
                    >
                      <Flex>
                        {pricing?.previousPrice && (
                          <Flex alignItems="end" justifyContent="start" pr={2}>
                            <Text
                              color="white"
                              fontSize="3xl"
                              as="del"
                              style={{ textDecorationColor: '#a541f4' }}
                            >
                              {pricing.previousPrice.yearly}
                            </Text>
                            <Text color="#9e9e9e" fontSize="sm" pb={2}>
                              /year
                            </Text>
                          </Flex>
                        )}
                        <Flex alignItems="end">
                          <Text color="white" fontSize="3xl">
                            {pricing.price.yearly}
                          </Text>
                          <Text color="#9e9e9e" fontSize="sm" pb={2}>
                            /year
                          </Text>
                          <Tag colorScheme="brand" mb={3} ml={2}>
                            SAVE 16%
                          </Tag>
                        </Flex>
                      </Flex>
                    </Radio>
                  </Flex>
                </RadioGroup>
                <Button
                  color="white"
                  colorScheme="brand"
                  size="xl"
                  width="200px"
                  height="48px"
                  mt={6}
                  mb={4}
                  onClick={() => handleChoosePlan(pricing)}
                  className="option-button"
                >
                  BUY NOW
                </Button>
              </Flex>
            </Box>
          ))}
          <Box p={5} mx={4} width="40%" className="option-box">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              height="full"
            >
              <Flex
                flexDirection="column"
                alignItems="start"
                className="box-title"
              >
                <Text fontSize="xl" color="white">
                  Team Dashboard
                </Text>
                <Text as="b" fontSize="3xl" color="white">
                  FITGMR Enterprise
                </Text>
              </Flex>
              <Divider my={2} width="80%" />
              <Flex
                spacing={3}
                flexDirection="column"
                mb={2}
                fontWeight="light"
                color="#bfbfbf"
                fontSize="lg"
              >
                <Flex my={4} pl={2} className="description-item">
                  <Box className="check-container">
                    <CircleCheck className="circle-check" />
                  </Box>
                  <Text ml={2}>
                    Ideal for large esports programs with multiple varsity teams
                  </Text>
                </Flex>
              </Flex>
              <a href="mailto:sales@fitgmr.gg" className="option-button">
                <Button
                  color="white"
                  colorScheme="brand"
                  size="xl"
                  width="200px"
                  height="48px"
                  my={2}
                  onClick={() => {}}
                >
                  CONTACT SALES
                </Button>
              </a>
            </Flex>
          </Box>
        </Flex>
      </Center>
      {isLoading && <LoadingModal isOpen={isLoading} onClose={() => {}} />}
      {isDonePromptOpen && (
        <PaymentSuccessPrompt
          isOpen={isDonePromptOpen}
          onSuccess={() => continueToDashboard()}
        />
      )}
      {isFailurePromptOpen && (
        <PaymentFailurePrompt
          isOpen={isFailurePromptOpen}
          onClose={() => setIsFailurePromptOpen(false)}
        />
      )}
      {checkoutModalOpen && !!clientSecret && (
        <Elements options={options} stripe={stripePromise} key={clientSecret}>
          <CheckoutModal
            clientSecret={clientSecret}
            amountDue={amountDue}
            plan={plan}
            planType={planType}
            isOpen={checkoutModalOpen}
            onSuccess={paymentSuccess}
            onClose={() => {
              setCheckoutModalOpen(false)
            }}
            onFail={paymentFailure}
          />
        </Elements>
      )}
      {isCouponCodeOpen && (
        <CouponCode
          isOpen={isCouponCodeOpen}
          onSuccess={(coupon) => handleCouponCodeSubmit(coupon)}
          onClose={() => closeModal()}
          onSkip={handleCouponCodeSkip}
        />
      )}
    </Page>
  )
}

export default Pricing
