import {
  Heading,
  Container,
  Divider,
  Box,
  HStack,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  useToast,
} from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons'
import RecommendationUpdateForm from '../../components/video-recommendation/ui/RecommendationUpdateForm'
import { VideoRecommendationType } from '../../components/video-recommendation/Video.recommendation.types'
import { RECOMMENDATION_MUTATIONS } from '../../components/video-recommendation/mutations/recommendation.mutations'

type ContentDetailProps = {
  setSelectedRow: (arg: any) => void
  selectedRow: VideoRecommendationType | null
  handleConfirmDelete: (id: string) => void
  loading: boolean
  refetch: () => void
}

export default function UpdateRecommendation({
  setSelectedRow,
  selectedRow,
  handleConfirmDelete,
  loading,
  refetch,
}: ContentDetailProps) {
  const toast = useToast()

  const [updateRecommendation] = useMutation(
    RECOMMENDATION_MUTATIONS.UPDATE_RECOMMENDATION,
    {
      onCompleted: () => {
        toast({
          position: 'top-left',
          render: () => (
            <Flex p="2" borderRadius="4" color="white" bg="green.600">
              <CheckCircleIcon alignSelf="center" />
              <Text ml="3" mt="1">
                Details were updated successfully!
              </Text>
            </Flex>
          ),
        })
        refetch()
      },
      onError: (error) => {
        console.log('error', error)
      },
    }
  )

  const handleSubmit = (data: any) => {
    const newObject = { ...data }
    delete newObject.category
    updateRecommendation({
      variables: {
        id: selectedRow?.id,
        data: {
          ...newObject,
        },
      },
    })
  }

  const selectedTabStyle = (bgColor: string) => ({
    color: 'white',
    borderRadius: 8,
    bg: bgColor,
  })

  if (!selectedRow) return null

  return (
    <>
      <Container>
        <HStack h="auto" pt="5" justifyContent="space-between">
          <Box>
            <Flex justifyContent="flex-start">
              <Heading mr="2" size="md" color="white">
                {selectedRow?.title}
              </Heading>
            </Flex>
          </Box>
          <CloseIcon
            cursor="pointer"
            boxSize={4}
            onClick={() => setSelectedRow(null)}
            alignSelf="start"
          />
        </HStack>
        <Divider />
        <Box position="relative">
          <Tabs mt={5} variant="unstyled">
            <TabList>
              <Tab _selected={selectedTabStyle('fitgmrBlue.400')}>Details</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p="0" pt="5">
                {selectedRow?.id && (
                  <RecommendationUpdateForm
                    isSubmitting={loading}
                    initialData={selectedRow}
                    onSubmit={handleSubmit}
                    loading={loading}
                    selectedRow={selectedRow}
                    handleConfirmDelete={handleConfirmDelete}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Container>
    </>
  )
}
