import {
  CategoryEnum,
  PillarCategory,
  VideoInterface,
} from '../content/store/content.types'

export type VideoRecommendationType = {
  id: string
  title: string
  description: string
  pillarSlug: PillarCategory | string
  questionSlug: CategoryEnum
  answer: string
  thumbnailUrl: string
  selectedThumbnail: string
  Video: VideoInterface
  url: string
}

export type CategoryOption = {
  activity: string
  label?: string
  options: string[]
}

export type PillarQuestionCategory = {
  [pillarName: string]: CategoryOption[]
}

export const PillarVideoCategories: PillarQuestionCategory[] = [
  {
    mental: [
      {
        activity: 'emotional-state',
        label: 'Self-Reflection',
        options: [
          'angry',
          'sad',
          'stressed',
          'discouraged',
          'tired',
          'meh',
          'bored',
          'unsure',
        ],
      },
      {
        activity: 'mindfulness-group',
        label: 'Meditation',
        options: ['0'],
      },
      {
        activity: 'breathwork-exercises',
        label: 'Breathing Exercises',
        options: ['0'],
      },
    ],
  },
  {
    sleep: [
      {
        activity: 'sleep-quality',
        options: ['terrible', 'neutral', 'good', 'great'],
      },
      {
        activity: 'sleep-disturbance',
        options: [
          'discomfort',
          'nightmare',
          'noise',
          'pet',
          'temperature',
          'toilet',
          'worry',
          'other',
        ],
      },
    ],
  },
  {
    physical: [
      {
        activity: 'pain',
        label: 'Discomfort',
        options: [
          'back',
          'elbows',
          'eyes',
          'fingers',
          'head',
          'hips',
          'knees',
          'neck',
          'shoulders',
          'wrist',
        ],
      },
      {
        activity: 'quick-drills-group',
        label: 'Quick Drills',
        options: ['mostly-seated'],
      },
      {
        activity: 'training-load-group',
        label: 'Movement',
        options: ['rest'],
      },
    ],
  },
  {
    lifestyle: [
      {
        activity: 'activities',
        label: 'Hobbies',
        options: ['none'],
      },
      {
        activity: 'green-space-time',
        label: 'Outdoors',
        options: ['none'],
      },
    ],
  },
  {
    nutrition: [
      {
        activity: 'water-drink',
        label: 'Water Intake',
        options: ['none'],
      },
      {
        activity: 'healthy-meals',
        label: 'Healthy Meals',
        options: ['none'],
      },
      {
        activity: 'fast-food',
        label: 'Fast Food',
        options: ['true'],
      },
      {
        activity: 'snacks',
        label: 'Snacks',
        options: ['unhealthy'],
      },
      {
        activity: 'sugars',
        label: 'Added Sugars',
        options: ['more than 30g'],
      },
      {
        activity: 'caffeine-group',
        label: 'Caffeine Consumption',
        options: ['late-caffeine'],
      },
    ],
  },
]
